import { Avatar, Form, Input, List, Modal, Select } from "antd";
import styled from "styled-components";

export const Header = styled.header`
  background: url(${props => props.bgColor}) no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  display: block;
  @media screen and (max-width: 768px) { 
    display: ${props => props.hideHeader || "block"};
  }
`

export const UserName = styled.p`
  font-family: "Helvetica Neue Light", Arial;    
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
`

export const FullName = styled.p`
  font-family: "Helvetica Neue Medium", Arial;    
  font-size: 34px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
`;

export const FollowingText = styled.p`
  font-family: "Helvetica Neue Roman", Arial;    
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  margin: 0px;
`;

export const DescriptionText = styled.p`
  font-family: "Helvetica Neue Light", Arial;    
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #E0E0E0;
  margin: 0px;
`

export const StyledButton = styled.button`
  width: ${props => props.width || "130px"};
  height: ${props => props.height || "36px"};
  padding: 8px 14px 8px 14px;
  text-wrap: nowrap;
  gap: 8px;
  border-radius: 24px;   
  background: ${props => props.bgColor};
  color: ${props => props.color};
  border: ${props => props.border || "1px solid #FFFFFF"};
  border-radius: ${props => props.borderRadius};
  box-shadow: 0px 1px 2px 0px #1018280D;
  font-family: "Helvetica Neue Light", Arial;  
  font-size: ${props => props.fontSize || "12px"};
  font-weight: ${props => props.fontWeight || "700"};
  line-height: 20px;
  letter-spacing: 0em;
  filter: ${props => props.disabled && "opacity(0.5)"};
  cursor: ${props => props.disabled && "not-allowed"};
`

export const StyledAvatar = styled(Avatar)`
  // background: ${props => props.bgColor};
  font-size: ${props => props.fontSize || "48px"};
  width: ${props => props.width || "160px"};
  height: ${props => props.height || "160px"};
`;

export const ListDescriptionWraper = styled.div`
  color: white;
  max-width: 806px;
`
export const ListItemTitle = styled.div`
  font-family: 'Helvetica Neue Roman', Arial;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #FFFFFF;
`;

export const ListDescription = styled.div`
  font-family: 'Helvetica Neue Roman', Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: #9E9E9E;
`;

export const BlurOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 40px;
  gap: 10px;
  border-radius: 20px;
  position: absolute;
  bottom: 50px;
  margin: 20px;
`;

export const Card = styled.div`
  background: ${props => props.bgColor || 'transparent'};
  color: white;
`;

export const Heading = styled.h1`
  font-size: 24px;
`;

export const Description = styled.p`
  font-family: 'Helvetica Neue Light', Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${props => props.color || '#FFFFFF'}
`;

export const Grid = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const WhiteText = styled.div`
  color: white;
`

export const SelectedEntities = styled.div`
  display: block;
  color: white;
  min-width: 35%;
  padding: 29px 10px 29px 10px;
  border-left: 1px solid #212121;
  @media screen and (max-width: 768px) { 
    display: none; 
  }
`

export const MobileSelectedEntities = styled.div`
  display: none;
  color: white;
  @media screen and (max-width: 768px) { 
    display: block; 
  }
`

export const HeadingText = styled.h2`
  font-family: 'Helvetica Neue Medium', Arial;
  font-size: ${props => props.fontSize || '32px'};
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
`

export const SupportingText = styled.p`
  font-family: 'Helvetica Neue Light', Arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
`

export const StyledInput = styled(Input)`
  background: ${props => props.bgColor || 'initial'} !important;
  color: ${props => props.color || 'initial'};
  padding: 6px 16px 6px 16px;
  &:hover, &:focus {
    background: ${props => props.bgColor || 'initial'};
  }
`

export const StyledLabel = styled.label`
  font-family: 'Helvetica Neue Light', Arial;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`
export const StyledItem = styled(Form.Item)`
  .ant-form-item-label label {
    color: #FFFFFF;
  }
  .ant-form-item-control textarea {
    background: #1E2026;
    color: #FFFFFF;
  }
`

export const DownloadModalHeading = styled.div`
  font-family: 'Helvetica Neue Roman', Arial;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: center;
`;

export const DownloadModalDescriptionText = styled.div`
  font-family: 'Helvetica Neue Light', Arial;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  margin: ${props => props.margin || "30px 0px"};
`;

export const GetItText = styled.div`
  font-size: 12px;
  letter-spacing: 1px;
`;

export const PlayStoreText = styled.div`
  font-size: 24px;
`;

export const DownloadModalWrapper = styled.div`
  margin: 30px; 
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    background: #000000;
    color: #FFFFFF;
  }
  .ant-modal-close-x {
    color: #BDBDBD;
  }
  .ant-modal-title {
    text-align: start;
    background: #000000;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: end;
  gap: 15px;
  margin-right: 10px;
  padding: 20px;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const StyledSelect = styled(Select)`
  width: 200px;
  height: 100%;
  .ant-select-selector {
    padding: 8px 14px 8px 14px !important;
  }
`;

export const StyledList = styled(List)`
  height: 70vh;
  overflow: auto;
`