import React, { useEffect, useState } from 'react';
import RecommendEntityLeftImg from '../assets/images/RecommendEntityLeftImg.svg';
import { Avatar, Flex, Form, Input, message } from 'antd';
import styled from 'styled-components';
import { StyledAvatar, DescriptionText, FollowingText, Header, StyledButton, Grid, BlurOverlay, Card, Heading, Description, WhiteText, HeadingText, SupportingText, StyledItem, StyledInput } from '../styles/ViewListScreenStyles';
import { ReactComponent as WaglLogo } from "../assets/images/WaglLogo.svg";
import { convertToTitleCaseWithSpaces, getRandomColor } from '../utils/commonFunctions';
import { recommendationRequest } from '../DataService';
import { useNavigate, useSearchParams } from 'react-router-dom';

const RecommendEntity = () => {
  const randomAvatarColor = getRandomColor();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [searchParams, setSearchParams] = new useSearchParams();
  const [disableBtn, setDisableBtn] = useState(false);

  const submitHandler = (formValues) => {
    localStorage.setItem("userInfo", JSON.stringify({
      id: searchParams.get('id'),
      user: data?.user,
      desc: data?.desc,
      title: data?.title,
      fullname: data?.user_profile?.fullname,
      username: data?.user_profile?.username,
      profile_picture: data?.user_profile?.profile_picture,
      name: formValues.name,
      email: formValues.email,
    }));
    navigate("/recommend-list");
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };  
  
  useEffect(() => {
    recommendationRequest(searchParams.get('id'))
    .then(({data}) => {setData(data); console.log("data = ", data);})
    .catch((error) => {
      if(error.response.data.message == "Recommendation Request has not been shared.") {
        message.error(error.response.data.message);
      }
      setDisableBtn(true);
    });
  },[])

  return (
    <Grid>
      <Header bgColor={RecommendEntityLeftImg} hideHeader="none">
        <BlurOverlay>
          <Card>
            <Heading>Share What&apos;s Good</Heading>
            <Description>
              Wagl is an app for sharing what&apos;s good. We&apos;ve all got lists of our favorite places, things, books, podcasts, shows, services, recipes, or whatever. Our friends have them too. wagl allows you to share them easily with others and discover what&apos;s good from one another.
            </Description>
          </Card>
        </BlurOverlay>
      </Header>
      <WhiteText>
        <div className='px-3'>
          <div className='d-flex justify-content-end p-md-4 py-3'><WaglLogo/></div>
          <div style={{maxWidth: "450px"}} className='m-auto'>
            <div>
              <HeadingText className='mb-4'>Recommend Something</HeadingText>
              <SupportingText>Details</SupportingText>
            </div>
            <Card className='rounded-4 p-3' bgColor='#1E2026'>
              <div className='d-flex gap-2 mb-3'>
                <StyledAvatar fontSize="22px" width="50px" height="50px" src={data?.user_profile?.profile_picture}>{convertToTitleCaseWithSpaces(data?.user_profile?.fullname)}</StyledAvatar>
                <div className='d-flex flex-column gap-1 justify-content-center align-items-start'>
                  <FollowingText>{data?.user_profile?.fullname}</FollowingText>
                  <DescriptionText>{data?.user_profile?.username}</DescriptionText>
                </div>
              </div>
              <Description color='#E0E0E0'>{data?.desc}</Description>
            </Card>
            <Description className='mt-3' color='#BDBDBD'>Please submit your name and email so that 	&quot;{data?.user_profile?.fullname}&quot; knows who shared this item with them. Thanks!</Description>
            <Form onFinish={submitHandler}  layout="vertical">
              <StyledItem
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required"
                  }
                ]}
              >
                <StyledInput placeholder='Enter your Full name' placeholderColor='#BDBDBE'  color='#FFFFFF' bgColor='#1E2026' className='border-0 rounded-5'/>
              </StyledItem>
              <StyledItem
                name="email"
                label="Email"
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('Email Address is required');
                      } else if (!isValidEmail(value)) {
                        return Promise.reject('Please enter a valid email address');
                      } else {
                        return Promise.resolve();
                      }
                    }
                  }
                ]}
              >
                <StyledInput placeholder='Enter your email address' placeholderColor='#BDBDBE' color='#FFFFFF'  bgColor='#1E2026' className='border-0 rounded-5'/>
              </StyledItem>
              <StyledButton
                bgColor="linear-gradient(90deg, #00C7F3 0%, #D0FCA6 100%)"
                color="#000000"
                border="border: 1px solid linear-gradient(90deg, #00C7F3 0%, #D0FCA6 100%)"
                type='submit'
                width="100%"
                className='mt-3 mb-4'
                disabled={disableBtn}
              >Next</StyledButton>
            </Form>
          </div>
        </div>
      </WhiteText>
    </Grid>
  );
};

export default RecommendEntity;
