import React, { useEffect, useState } from 'react'
import { DatePicker, Flex, Form, Image, Input, message, Modal, Select, Typography, Upload } from "antd";
import { ReactComponent as DefaultImageIcon } from "../assets/images/defaultImage.svg";
import styled from "styled-components";
import { StyledInput, StyledModal, WhiteText, StyledItem, StyledButton, Footer } from '../styles/ViewListScreenStyles';
import { createEntityFromWeb } from '../DataService';
const { Dragger } = Upload;

const CreateEntityModal = ({ isModalOpen, setModalOpen, setEntities, isCreateModalLoading, setIsCreateModalLoading }) => {
  const [image, setImage] = useState(null);
  const [form] = Form.useForm();
  
  useEffect(() => {
    form.resetFields();
  },[form, isModalOpen])

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload(file) {
      const fileType = file.type;
      if (fileType !== "image/png" && fileType !== "image/jpg" && fileType !== "image/jpeg") {
        message.error("File should be of type PNG, JPG, or JPEG");
        return;
      }
      setImage(file);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinish = (formValues) => {
    let body = new FormData();
    const recommendation_request_id = JSON.parse(localStorage.getItem("userInfo"))?.id;
    body.append("title", formValues.title);
    formValues.desc && body.append("desc", formValues.desc);
    body.append("image", image);
    formValues.url && body.append("url", formValues.url);
    body.append("category", formValues.category);
    body.append("recommendation_request", recommendation_request_id);
    setIsCreateModalLoading(true);
    createEntityFromWeb(body)
    .then(( res ) => {
      if(res.status == 200) {
        message.success("Entity Created Successfully!");
        setEntities(prevEntities => [...prevEntities, res.data]);
        setModalOpen(false);
        setIsCreateModalLoading(false);
      }
    })
    .catch(error => {
      console.log(error);
      setIsCreateModalLoading(false);
    });
  };

  return (
    <StyledModal
      open={isModalOpen}
      okText="Save Changes"
      title="Add new Entity"
      width="794px"
      heigth="637px"
      footer={null}
      onCancel={() => { setModalOpen(false); form.resetFields() }}
    >
      <Form name="createEntity" onFinish={onFinish} form={form} layout="vertical">
        <StyledItem
          name="image"
        >
          <Dragger {...props}>
            {
              image ? 
              <Image src={URL.createObjectURL(image)}  style={{ maxWidth: "249px", maxHeight: "140px" }}/>:
              <>
                <p className="ant-upload-drag-icon">
                  <DefaultImageIcon />
                </p>
                <WhiteText className="ant-upload-text">Drag & Drop your images here</WhiteText>
                <WhiteText className="ant-upload-hint">PNG, JPG, JPEG</WhiteText>
              </>
            }
          </Dragger>
        </StyledItem>
        <StyledItem
          name="title"
          label="Entity Title"
          rules={[
            {
              required: true,
              message: "Title is required"
            }
          ]}
        >
          <StyledInput placeholder="Add a title" maxLength={200} placeholderColor='#BDBDBE' color='#FFFFFF'  bgColor='#1E2026' className='border-0 rounded-5' size='large'/>
        </StyledItem>
        <StyledItem
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: "Please select a Category"
            }
          ]}
        >
          <Select 
            className='w-50'
            placeholder="Select Category"
            options={[
              {label: "Movies/TV Shows", value: 1},
              {label: "Podcasts", value: 2},
              {label: "Places", value: 3},
            ]}
          />
        </StyledItem>
        <StyledItem 
          name="desc" 
          label="Description"
        >
          <StyledInput.TextArea placeholder="Add a description" maxLength={200} placeholderColor='#BDBDBE' color='#FFFFFF'  bgColor='#1E2026' className='border-0 rounded-3' size='large'/>
        </StyledItem>
        <StyledItem 
          name="url" 
          label="Website Link (If any)"
          rules={[
            {
              type: "url",
              message: "Please enter a valid URL",
            },
          ]}
        >
          <StyledInput placeholder="Add a link" maxLength={200} placeholderColor='#BDBDBE' color='#FFFFFF'  bgColor='#1E2026' className='border-0 rounded-5' size='large'/>
        </StyledItem>
        <Footer>
          <StyledButton
            bgColor="transparent"
            color="#FFFFFF"
            onClick={() => {setModalOpen(false); form.resetFields()}}
          >Cancel</StyledButton>
          <StyledButton 
            bgColor="linear-gradient(90deg, #00C7F3 0%, #D0FCA6 100%)"
            color="#000000"
            border="border: 1px solid linear-gradient(90deg, #00C7F3 0%, #D0FCA6 100%)"
            onClick={() => {}}
            width="130px"
            fontWeight="700"
            disabled={isCreateModalLoading}
          >Save</StyledButton>
        </Footer>
      </Form>
    </StyledModal>
  );
};

export default CreateEntityModal;