import { message } from "antd";
import axios from "axios";

const BASE_URL = "https://wagl-43546-staging.botics.co";
const RECOMMENDATION_REQUEST = (id) => `/api/v1/recommendationrequest/${id}/view_recommendation_request_from_web/`;
const CREATE_RECOMMENDATION = "/api/v1/recommendation/create_recommendation_from_web/";
const CREATE_ENTITY = "/api/v1/entity/create_entity_from_web/";
const VIEW_RECOMMENDATION = (categoryType, q, page) => `/api/v1/search_entities_from_web/?category_type=${categoryType}&q=${q}&page=${page}`;
const GET_VIEW_LIST = `/api/v1/list/detail`;

export const getRecommentationList = (categoryType, search, page) => {
    return axios
    .get(BASE_URL+VIEW_RECOMMENDATION(categoryType, search, page))
}

export const recommendationRequest = (id) => {
    return axios
    .get(BASE_URL+RECOMMENDATION_REQUEST(id))
}

export const createRecommendationFromWeb = (formValues) => {
    return axios
    .post(BASE_URL+CREATE_RECOMMENDATION, formValues);
}

export const createEntityFromWeb = (formValues) => {
    return axios
    .post(BASE_URL+CREATE_ENTITY, formValues);
}

export const getViewList = (id) => {
    return axios
    .post(BASE_URL+GET_VIEW_LIST, {id});
}
