import React, { useEffect, useState } from 'react'
import { Card, Description, DescriptionText, FollowingText, Footer, FullName, Header, HeadingText, ListDescription, ListDescriptionWraper, ListItemTitle, MobileSelectedEntities, SelectedEntities, StyledAvatar, StyledButton, StyledInput, StyledSelect, SupportingText, UserName, WhiteText } from '../styles/ViewListScreenStyles'
import { ReactComponent as WaglLogo } from "../assets/images/WaglLogo.svg";
import HeaderBackground from "../assets/images/HeaderBackground.svg";
import { convertToTitleCaseWithSpaces, getRandomColor } from '../utils/commonFunctions';
import { PlusOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Input, List, Pagination, Select, message } from 'antd';
import styled from 'styled-components';
import CreateEntityModal from '../modals/CreateEntityModal';
import { createRecommendationFromWeb, getRecommentationList } from '../DataService';
import RecommendationSuccessModal from '../modals/RecommendationSuccessModal';

const RecommendList = () => {
  const randomAvatarColor = getRandomColor();
  const [checkedItems, setCheckedItems] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isRecommendModalOpen, setRecommendModalOpen] = useState(false);
  const [recommendData, setrecommendData] = useState();
  const [search, setSearch] = useState("");
  const [entityList, setEntityList] = useState([]);
  const [category, setCategory] = useState(1);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(1);
  const [total, setTotal] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateModalLoading, setIsCreateModalLoading] = useState(false);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    setrecommendData(JSON.parse(localStorage.getItem("userInfo")));
    setCheckedItems([])
    setEntityList([])
    const getData = setTimeout(() => { 
        search && setIsLoading(true)
        search && getRecommentationList(category ,search, next)
        .then(({data}) => {setTotal(data.count); setEntityList(data); setIsLoading(false)})
        .catch((error => console.log(error)));
      }, 1000)
    return () => clearTimeout(getData);
  }, [search, category, next]);

  const submitRecommendation = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const global_entities = checkedItems.map(item => ({category_type: category, id: item.imdbID ?? item.id  ?? item.place_id}))
    const user_entities = entities.map(item => item.id);
    const formValues = {
      title: userInfo.title,
      desc: userInfo.desc,
      from_user_name: userInfo.name,
      from_user_email: userInfo.email,
      to_user: userInfo.user,
      recommendation_request: userInfo.id,
      entities: user_entities,
      global_entities: global_entities,
    }
    createRecommendationFromWeb(formValues)
    .then(data => {
      if(data.status == 200) {
        setRecommendModalOpen(true);
        setCheckedItems([]);
        setEntities([]);
      }
    })
    .catch(error => message.error(error.response.data.message));
  }

  const handleCheckboxChange = (e, item) => {
    toggleCheckedItem(item);
  };

  const handleEntityDelete = (index) => {
    const updatedEntities = [...entities];
    updatedEntities.splice(index, 1);
    setEntities(updatedEntities);
  };
  
  const handleCheckedItemDelete = (index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems.splice(index, 1);
    setCheckedItems(updatedCheckedItems);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      const id = checkedItems[index].imdbID ?? checkedItems[index].id  ?? checkedItems[index].place_id
      if (checkbox.id == id) {
        checkbox.checked = false;
      }
    });
  };

  const toggleCheckedItem = (item) => {
    const isChecked = checkedItems.some((checkedItem) => checkedItem.Title === item.Title);
    if (isChecked) {
      setCheckedItems(checkedItems.filter((checkedItem) => checkedItem.Title !== item.Title));
    } else {
      setCheckedItems([...checkedItems, item]);
    }
  };

  return (
    <>
      <RecommendationSuccessModal isModalOpen={isRecommendModalOpen} setModalOpen={setRecommendModalOpen}/>
      <CreateEntityModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} setEntities={setEntities} isCreateModalLoading={isCreateModalLoading} setIsCreateModalLoading={setIsCreateModalLoading}/>
      <Header bgColor={HeaderBackground} className='mb-3'>
        <div className='p-4'><WaglLogo/></div>
      </Header>
      <div className='d-flex'>
        <WhiteText className='m-auto' style={{maxWidth: "900px", padding: "0px 20px"}}>
          <HeadingText fontSize="26px">Share a Recommendation with Kandel</HeadingText>
          <Description color='#BDBDBD'>Select multiple entities or create new to sent to users</Description>
          <Card className='rounded-4 p-3 mb-5' bgColor='#121212'>
            <div className='d-flex gap-2 mb-3'>
              <StyledAvatar fontSize="22px" width="50px" height="50px" src={recommendData?.profile_picture}>{convertToTitleCaseWithSpaces(recommendData?.fullname)}</StyledAvatar>
              <div className='d-flex flex-column gap-1 justify-content-center align-items-start'>
                <FollowingText>{recommendData?.fullname}</FollowingText>
                <DescriptionText>{recommendData?.username}</DescriptionText>
              </div>
            </div>
            <Description color='#E0E0E0'>{recommendData?.desc}</Description>
          </Card>
          {(checkedItems.length > 0 || entities.length > 0) && 
          <MobileSelectedEntities>
            <SupportingText>Selected Entities</SupportingText>
            { entities.length > 0 && 
            <List
              dataSource={entities}
              renderItem={(item, index) => (
                <List.Item className='justify-content-start gap-3'>
                  <StyledAvatar fontSize="22px" width="75px" height="65px" src={
                    item?.image
                  }>{convertToTitleCaseWithSpaces(item?.title)}
                  </StyledAvatar>
                  <ListDescriptionWraper style={{ color: 'white' }}>
                    <ListItemTitle className='mb-2'>{
                      item?.title
                    }</ListItemTitle>
                    <ListDescription className='text-secondary'>{
                      item?.desc
                    }</ListDescription>
                  </ListDescriptionWraper>
                  <div className="text-danger ms-auto pe-2 fs-5" onClick={() => handleEntityDelete(index)}><DeleteOutlined/></div>
                </List.Item>
              )}
            />
            }
            { checkedItems.length > 0 &&
            <List
              dataSource={checkedItems}
              renderItem={(item, index) => (
                <List.Item className='justify-content-start gap-3'>
                  <StyledAvatar fontSize="22px" width="75px" height="65px" src={
                    item?.Poster ??
                    item?.image
                  }>{convertToTitleCaseWithSpaces(item?.title ??item?.Title ?? item?.structured_formatting?.main_text)}
                  </StyledAvatar>
                  <ListDescriptionWraper style={{ color: 'white' }}>
                    <ListItemTitle className='mb-2'>{
                      item?.title ??
                      item?.Title ??
                      item?.structured_formatting?.main_text
                    }</ListItemTitle>
                    <ListDescription className='text-secondary'>{
                      item?.desc ??
                      item?.Type ??
                      item?.structured_formatting?.secondary_text ??
                      item?.description
                    }</ListDescription>
                  </ListDescriptionWraper>
                  <div className="text-danger ms-auto pe-2 fs-5" onClick={() => handleCheckedItemDelete(index)}><DeleteOutlined/></div>
                </List.Item>
              )}
            />
            }
          </MobileSelectedEntities>
          }
          <div className='d-flex gap-3 flex-md-nowrap flex-wrap'>
            <StyledInput placeholder='Search for suggestions' maxLength={200} placeholderColor='#BDBDBE' color='#FFFFFF'  bgColor='#1E2026' className='border-0 rounded-5' size='large' onChange={(event) => setSearch(event.target.value)}/>
            <StyledSelect
              onChange={(e) => setCategory(e)}
              value={category}
              options={[
                {label: "Movies/TV Shows", value: 1},
                {label: "Podcasts", value: 2},
                {label: "Places", value: 3},
              ]}
            />
            <StyledButton 
              bgColor="transparent" 
              border="1.5px solid #FFFFFF"
              color="#FFFFFF"
              borderRadius="24px"
              height="50px"
              width="auto"
              fontSize="16px"
              fontWeight="500"
              onClick={() => setModalOpen(true)}
            ><PlusOutlined className='me-2'/>Create a new Entry</StyledButton>
          </div>
          {
          total > -1 &&
          <Pagination
            className='text-end py-2'
            total={total}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            showSizeChanger={false}
            onChange={(e) => setNext(e)}
          />
          }
          <List 
            style={{
              height: "70vh",
              overflow: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            loading={isLoading}
            itemLayout="horizontal"
            dataSource={entityList.results}
            renderItem={(item, index) => (
              <List.Item className='gap-3 pe-2'>
                <div className='d-flex align-items-center gap-2'>
                  <StyledAvatar fontSize="22px" width="87.23px" height="81px" src={
                    item?.Poster ??
                    item?.image
                  }>{convertToTitleCaseWithSpaces(item?.title ??item?.Title ?? item?.structured_formatting?.main_text)}
                  </StyledAvatar>
                  <ListDescriptionWraper style={{ color: 'white', maxWidth: "50%" }}>
                    <ListItemTitle className='mb-2'>{
                      item?.title ??
                      item?.Title ??
                      item?.structured_formatting?.main_text
                    }</ListItemTitle>
                    <ListDescription className='text-secondary'>{
                      item?.desc ??
                      item?.Type ??
                      item?.structured_formatting?.secondary_text ??
                      item?.description
                    }</ListDescription>
                  </ListDescriptionWraper>
                </div>
                <Checkbox id={item.imdbID ?? item.id  ?? item.place_id} checked={checkedItems.some((checkedItem) => checkedItem.Title === item.Title)} onChange={(e) => handleCheckboxChange(e, item)} style={{ color: '#121212' }} />
              </List.Item>
            )}
          />
        </WhiteText>
        { (checkedItems.length > 0 || entities.length > 0) && 
          <SelectedEntities>
            <SupportingText>Selected Entities</SupportingText>
            { entities.length > 0 && 
            <List
              dataSource={entities}
              renderItem={(item, index) => (
                <List.Item className='justify-content-start gap-3'>
                  <StyledAvatar fontSize="22px" width="75px" height="65px" src={
                    item?.image
                  }>{convertToTitleCaseWithSpaces(item?.title)}
                  </StyledAvatar>
                  <ListDescriptionWraper style={{ color: 'white' }}>
                    <ListItemTitle className='mb-2'>{
                      item?.title
                    }</ListItemTitle>
                    <ListDescription className='text-secondary'>{
                      item?.desc
                    }</ListDescription>
                  </ListDescriptionWraper>
                  <div className="text-danger ms-auto pe-2 fs-5" onClick={() => handleEntityDelete(index)}><DeleteOutlined/></div>
                </List.Item>
              )}
            />
            }
            {checkedItems.length > 0 &&
            <List
              dataSource={checkedItems}
              renderItem={(item, index) => (
                <List.Item className='justify-content-start gap-3'>
                  <StyledAvatar fontSize="22px" width="75px" height="65px" src={
                    item?.Poster ??
                    item?.image
                  }>{convertToTitleCaseWithSpaces(item?.title ??item?.Title ?? item?.structured_formatting?.main_text)}
                  </StyledAvatar>
                  <ListDescriptionWraper style={{ color: "white", maxWidth: "75%" }}>
                    <ListItemTitle className='mb-2'>{
                      item?.title ??
                      item?.Title ??
                      item?.structured_formatting?.main_text
                    }</ListItemTitle>
                    <ListDescription className='text-secondary'>{
                      item?.desc ??
                      item?.Type ??
                      item?.structured_formatting?.secondary_text ??
                      item?.description
                    }</ListDescription>
                  </ListDescriptionWraper>
                  <div className="text-danger ms-auto pe-2 fs-5" onClick={() => handleCheckedItemDelete(index)}><DeleteOutlined /></div>
                </List.Item>
              )}
            />
            }
          </SelectedEntities>
        }
      </div>
      <Footer>
        <StyledButton 
          bgColor="transparent"
          color="#FFFFFF"
          onClick={() => setCheckedItems([])}
        >Cancel</StyledButton>
        <StyledButton 
          bgColor="linear-gradient(90deg, #00C7F3 0%, #D0FCA6 100%)"
          color="#000000"
          border="border: 1px solid linear-gradient(90deg, #00C7F3 0%, #D0FCA6 100%)"
          onClick={submitRecommendation}
          width="auto"
          fontWeight="700"
        >Share Recommendation</StyledButton>
      </Footer>
    </>
  )
}

export default RecommendList;