import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import { ReactComponent as WaglLogo } from "../assets/images/WaglLogo.svg";
import HeaderBackground from "../assets/images/HeaderBackground.svg";
import {Avatar, List, message} from "antd";
import { convertToTitleCaseWithSpaces, getRandomColor } from '../utils/commonFunctions';
import VirtualList from 'rc-virtual-list';
import DownloadAppModal from '../modals/DownloadAppModal';
import { StyledAvatar, StyledButton, DescriptionText, FollowingText, FullName, Header, ListDescription, ListDescriptionWraper, ListItemTitle, UserName } from '../styles/ViewListScreenStyles';
import { getViewList } from '../DataService';
import { useLocation, useParams, useSearchParams } from "react-router-dom";



const ViewListScreen = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [searchParams, setSearchParams] = new useSearchParams();
 
  const randomAvatarColor = getRandomColor();

  useEffect(() => {
    getViewList(searchParams.get('id'))
    .then(res => {
      if(res.status == 200) {
        setData(res.data);
      }
    })
    .catch(error => {
        if(error.response.data.error == "List not found.") {
            message.error(error.response.data.error);
        }
    });
  },[]); 

  return (
    <div>
        <DownloadAppModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        <Header bgColor={HeaderBackground}>
          <div className='p-4'><WaglLogo/></div>
          <div className='d-flex justify-content-center pb-4'>
            <div className='d-flex justify-content-center gap-3 flex-wrap' style={{ "max-width": "904px"}}>
                <StyledAvatar bgColor={randomAvatarColor} src={data.user_profile?.profile_picture}>{convertToTitleCaseWithSpaces(data.user_profile?.fullname)}</StyledAvatar>
                <div className='d-flex flex-column align-items-start gap-1'>
                    <UserName>{data.user_profile?.username}</UserName>
                    <FullName>{data.user_profile?.fullname}</FullName>
                    <div className='d-flex gap-3'>
                      <FollowingText>{data.user_profile?.list_count} Lists</FollowingText>
                      <FollowingText>{data.user_profile?.follower_count} Follower</FollowingText>
                      <FollowingText>{data.user_profile?.followed_count} Following</FollowingText>
                    </div>
                    <DescriptionText>{data.user_profile?.bio}</DescriptionText>
                    <div className='d-flex gap-3'>
                      <StyledButton 
                        bgColor="linear-gradient(90deg, #00C7F3 0%, #D0FCA6 100%)"
                        color="#000000"
                        border="border: 1px solid linear-gradient(90deg, #00C7F3 0%, #D0FCA6 100%)"
                        onClick={() => setIsModalOpen(true)}
                      >Follow</StyledButton>
                      <StyledButton 
                        bgColor="transparent"
                        color="#FFFFFF"
                        onClick={() => setIsModalOpen(true)}
                      >Chat</StyledButton>
                    </div>
                </div>
            </div>
          </div>
        </Header>
        <main className='d-flex justify-content-center'>
        <ListWraper>
            <ListItemTitle>Shared List</ListItemTitle>
            <List>
              <List.Item className='gap-3'>
                <StyledAvatar fontSize="22px" width="87.23px" height="81px" src={data.image}>{convertToTitleCaseWithSpaces(data.title)}</StyledAvatar>
                <ListDescriptionWraper style={{ color: 'white' }}>
                  <ListItemTitle className='mb-2'>{data.title}</ListItemTitle>
                  <ListDescription className='text-secondary'>{data.desc}</ListDescription>
                </ListDescriptionWraper>
              </List.Item>
            </List>
          </ListWraper>
        </main>
        <main className='d-flex justify-content-center'>
          <ListWraper>
            <ListItemTitle>List</ListItemTitle>
            <ListDescription>{data.entities?.length} entries</ListDescription>
            <List
              itemLayout="horizontal"
              dataSource={data.entities}
              renderItem={(item, index) => (
                <List.Item className='gap-3'>
                  <StyledAvatar fontSize="22px" width="87.23px" height="81px" src={item.image}>{convertToTitleCaseWithSpaces(item.title)}</StyledAvatar>
                  <ListDescriptionWraper style={{ color: 'white' }}>
                    <ListItemTitle className='mb-2'>{item.title}</ListItemTitle>
                    <ListDescription className='text-secondary'>{item.desc}</ListDescription>
                  </ListDescriptionWraper>
                </List.Item>
              )}
            />
          </ListWraper>
        </main>
    </div>
  )
}

const ListWraper = styled.div`
  max-width: 904px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export default ViewListScreen