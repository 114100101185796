import { Modal } from 'antd'
import React from 'react'
import styled from 'styled-components';
import { ReactComponent as PlayStoreLogo } from "../assets/images/PlayStoreLogo.svg";
import { ReactComponent as AppleLogo } from "../assets/images/AppleLogo.svg";
import { StyledButton, DescriptionText, DownloadModalWrapper, DownloadModalHeading, DownloadModalDescriptionText, GetItText, PlayStoreText, StyledModal } from '../styles/ViewListScreenStyles'

const DownloadAppModal = ({isModalOpen, setIsModalOpen}) => {
  return (
    <StyledModal
        open={isModalOpen} 
        footer={null}
        width={576}
        height={354}
        onCancel={() => setIsModalOpen(false)}
        centered
    >
      <DownloadModalWrapper>
        <DownloadModalHeading>Download Mobile App</DownloadModalHeading>
        <DownloadModalDescriptionText>In order to perform actions, you need to<br/> download the app</DownloadModalDescriptionText>
        <div className='d-flex gap-3 flex-wrap'>
          <StyledButton 
            bgColor="transparent" 
            border="1.5px solid #FFFFFF"
            borderRadius="8px"
            width="244px"
            height="72px"
          >
            <div className='d-flex gap-2'>
              <PlayStoreLogo/>
              <div className='d-flex flex-column text-white align-items-start'>
                <GetItText>GET IT ON</GetItText>
                <PlayStoreText>Google Play</PlayStoreText>
              </div>
            </div>
          </StyledButton>
          <StyledButton 
            bgColor="transparent" 
            border="1.5px solid #FFFFFF"
            borderRadius="8px"
            width="244px"
            height="72px"
          >
            <div className='d-flex gap-2'>
              <AppleLogo/>
              <div className='d-flex flex-column text-white align-items-start'>
                <GetItText>GET IT ON</GetItText>
                <PlayStoreText>App Store</PlayStoreText>
              </div>
            </div>
          </StyledButton>
        </div>
      </DownloadModalWrapper>
    </StyledModal>
  )
}

export default DownloadAppModal