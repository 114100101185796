import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import ViewListScreen from './pages/ViewListScreen';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ConfigProvider, theme } from "antd";
import RecommendEntity from './pages/RecommendEntity';
import RecommendList from './pages/RecommendList';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';

const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Router>
        <Routes>
          <Route path="/view-list" element={<ViewListScreen/>}/>
        </Routes>
        <Routes>
          <Route path="/recommend" element={<RecommendEntity/>}/>
        </Routes>
        <Routes>
          <Route path="/recommend-list" element={<RecommendList/>}/>
        </Routes>
        <Routes>
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
        </Routes>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
