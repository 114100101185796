export const ColorList =[
"#FF0000",
"#FF6347",
"#DC143C",
"#FF4500",
"#FF8C00",
"#FFA07A",
"#CD5C5C",
"#B22222",
"#008000",
"#32CD32",
"#00FF00",
"#7CFC00",
"#00FF7F",
"#ADFF2F",
"#228B22",
"#006400",
"#0000FF",
"#4169E1",
"#6495ED",
"#87CEEB",
"#00BFFF",
"#1E90FF",
"#00CED1",
"#4682B4",
"#FFFF00",
"#FFD700",
"#FFA500",
"#FFDAB9",
"#FFFFE0",
"#F0E68C",
"#BDB76B",
"#FFEC8B",
"#800080",
"#9400D3",
"#8A2BE2",
"#9932CC",
"#BA55D3",
"#9370DB",
"#4B0082",
"#6A5ACD",
"#00FFFF",
"#00CED1",
"#AFEEEE",
"#20B2AA",
"#40E0D0",
"#48D1CC",
"#00FFFF",
"#7FFFD4",
"#808080",
"#A9A9A9",
"#D3D3D3",
"#C0C0C0",
"#DCDCDC",
"#F5F5F5",
"#696969",
"#778899",
"#FFC0CB",
"#FF69B4",
"#FF1493",
"#FFB6C1",
"#C71585",
"#DB7093",
"#FFC0CB",
"#FF69B4",
"#A52A2A",
"#8B4513",
"#D2691E",
"#CD853F",
"#DEB887",
"#F4A460",
"#D2B48C",
"#BC8F8F",
"#008080",
"#008B8B",
"#20B2AA",
"#5F9EA0",
"#00CED1",
"#00FFFF",
"#48D1CC",
"#40E0D0"
];